<template>
  <el-dropdown
    :show-timeout="0"
    placement="bottom"
    @command="handleSetLanguage">
    <div style="padding: 12px">
      <em class="iconfont icon-translate"></em>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        :disabled="$i18n.locale==='cn'"
        command="cn">
        简体中文
      </el-dropdown-item>
      <el-dropdown-item
        :disabled="$i18n.locale==='en'"
        command="en">
        English
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>

export default {
  name: 'LanguageSelector',
  data () {
    return {}
  },
  methods: {
    handleSetLanguage (lang) {
      this.$i18n.locale = lang
      localStorage.language = lang
    }
  }
}
</script>

<style lang="scss" scoped>
  .lang {
    display: flex;
    align-items: center;
    font-size: 12px;

    i {
      font-size: 12px;
      margin-right: 0;
    }
  }
</style>
